import React, { Fragment } from "react";

const LandingPage = () => (
  <Fragment>
    <div className="relative bg-white overflow-hidden">
      <div className="max-w-7xl mx-auto">
        <div className="relative z-10 pb-8 bg-white sm:pb-16 md:pb-20 lg:max-w-2xl lg:w-full lg:pb-28 xl:pb-32">
          <svg
            className="hidden lg:block absolute right-0 inset-y-0 h-full w-48 text-white transform translate-x-1/2"
            fill="currentColor"
            viewBox="0 0 100 100"
            preserveAspectRatio="none"
            aria-hidden="true"
          >
            <polygon points="50,0 100,0 50,100 0,100" />
          </svg>


          <main className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
            <div className="sm:text-center lg:text-left">
              <h1 className="text-4xl tracking-tight font-extrabold text-gray-900 sm:text-5xl md:text-6xl">
                <span className="block xl:inline">Meet the habit tracker that</span>{' '}
                <span className="block text-blue-500 xl:inline">changes mood to reflect your habit patterns.</span>
              </h1>
              <p className="mt-3 text-base text-gray-500 sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-xl lg:mx-0">
                Designed to help you live a balanced life
              </p>
              <p className="mt-3 text-base text-gray-500 sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-xl lg:mx-0">
                Create areas to track habits against, and watch your digital garden change at the end of each day, depending on whether you have stuck to them.
              </p>
              <div className="mt-5 sm:mt-8 sm:flex sm:justify-center lg:justify-start">
                <div className="rounded-md shadow">
                  <a
                    href="#"
                    className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-blue-500 hover:bg-blue-700 md:py-4 md:text-lg md:px-10"
                  >
                    Get started
                  </a>
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
      <div className="lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2">
        <img
          className="h-56 w-full object-cover sm:h-72 md:h-96 lg:w-full lg:h-full"
          src="overview_image.png"
          alt=""
        />
      </div>

    </div>
    <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 relative">
      <h2 id="faq-title" className="font-display text-3xl tracking-tight text-slate-900 sm:text-4xl">
        How does it work?
      </h2>
      <p className="mt-4 text-lg tracking-tight text-slate-700">
        <ul>
          <li>1) Create your areas, and add each habit you want to track.</li>
          <li>2) Check off your habits throughout the day.</li>


        </ul>
        <br />
        At midnight, providing all habits are complete in a particular area, you will see your health increase (or decrease). If habits were completed across <span className="inline font-extrabold">all</span> areas, then your health will increase, causing a positive / negative shift in the mood of your habit tracker entirely.
        Create your areas, and add each habit you want to track.

      </p>
    </div>
  </Fragment >
);

export default LandingPage;
