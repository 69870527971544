import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';

const LoginButton = ({
  className
}: {
  className: string
}) => {
  const { loginWithRedirect } = useAuth0();
  return (
    <button
      className="inline-flex items-center justify-center px-3 py-2 border border-transparent text-base text-md font-medium rounded-md text-white bg-green-600 hover:bg-green-700"
      onClick={() => loginWithRedirect()}
    >
      Login
    </button>
  );
};

export default LoginButton;