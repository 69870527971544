/* This example requires Tailwind CSS v2.0+ */
import { Fragment } from 'react'
import { Disclosure, Menu, Transition } from '@headlessui/react'
import { BellIcon, MenuIcon, XIcon } from '@heroicons/react/outline'
import { Link, NavLink } from 'react-router-dom'
import AuthenticationButton from './AuthenticationButton'
//ts-ignore

import { UserCircleIcon } from '@heroicons/react/outline'


// debugger
// pattern.toSvg() // => <svg width="100%" height="100%" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">...</svg>

function classNames(...classes: any) {
  return classes.filter(Boolean).join(' ')
}

export default function Example({ isAuthenticated, backgroundColors = ["", ""] }: { isAuthenticated: boolean, backgroundColors: string[] }) {
  let navigation = [
    { name: 'Overview', href: '/' },
    { name: 'Manage Areas / Habits', href: 'settings' },
    { name: "What's on today?", href: 'todo_today' },
    // TODO add stats
  ]

  if (!isAuthenticated) {
    navigation = [
      { name: 'About', href: '/' },
    ]
  }

  return (
    <Disclosure as="nav" className="z-20 fixed h-20 container mx-auto" style={{ background: `linear-gradient(${backgroundColors[0]}, ${backgroundColors[1]})` }} >
      {({ open, close }) => (
        <>
          <div className="">
            <div className="px-3 sm:px-0 flex items-center justify-between h-16">
              <div className="absolute flex items-center sm:hidden">
                {/* Mobile menu button*/}
                <Disclosure.Button className="inline-flex items-center justify-center p-2 rounded-md text-gray-900 hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                  <span className="sr-only">Open main menu</span>
                  {open ? (
                    <XIcon className="block h-6 w-6" aria-hidden="true" />
                  ) : (
                    <MenuIcon className="block h-6 w-6" aria-hidden="true" />
                  )}
                </Disclosure.Button>
              </div>
              {/* Logo */}
              <div className="flex-1 flex items-center justify-center sm:items-stretch sm:justify-start">
                <div className="flex-shrink-0 flex items-center text-black">
                  <svg xmlns="http://www.w3.org/2000/svg" className="block lg:hidden h-8 w-auto" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                    <path strokeLinecap="round" strokeLinejoin="round" d="M9 12l2 2 4-4M7.835 4.697a3.42 3.42 0 001.946-.806 3.42 3.42 0 014.438 0 3.42 3.42 0 001.946.806 3.42 3.42 0 013.138 3.138 3.42 3.42 0 00.806 1.946 3.42 3.42 0 010 4.438 3.42 3.42 0 00-.806 1.946 3.42 3.42 0 01-3.138 3.138 3.42 3.42 0 00-1.946.806 3.42 3.42 0 01-4.438 0 3.42 3.42 0 00-1.946-.806 3.42 3.42 0 01-3.138-3.138 3.42 3.42 0 00-.806-1.946 3.42 3.42 0 010-4.438 3.42 3.42 0 00.806-1.946 3.42 3.42 0 013.138-3.138z" />
                  </svg>
                  <svg xmlns="http://www.w3.org/2000/svg" className="hidden lg:block h-8 w-auto" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                    <path strokeLinecap="round" strokeLinejoin="round" d="M9 12l2 2 4-4M7.835 4.697a3.42 3.42 0 001.946-.806 3.42 3.42 0 014.438 0 3.42 3.42 0 001.946.806 3.42 3.42 0 013.138 3.138 3.42 3.42 0 00.806 1.946 3.42 3.42 0 010 4.438 3.42 3.42 0 00-.806 1.946 3.42 3.42 0 01-3.138 3.138 3.42 3.42 0 00-1.946.806 3.42 3.42 0 01-4.438 0 3.42 3.42 0 00-1.946-.806 3.42 3.42 0 01-3.138-3.138 3.42 3.42 0 00-.806-1.946 3.42 3.42 0 010-4.438 3.42 3.42 0 00.806-1.946 3.42 3.42 0 013.138-3.138z" />
                  </svg>
                </div>
                {/* Nav Links */}
                <div className="hidden sm:block sm:ml-6 flex-1">
                  <div className="flex items-center">
                    {navigation.map((item) => (
                      <NavLink exact to={item.href} key={item.name} className={isActive => {
                        return classNames(
                          isActive ? 'bg-gray-900 text-white' : 'text-black-300 hover:bg-gray-700 hover:text-white',
                          'mx-1 px-3 py-2 rounded-md text-md font-medium'
                        )
                      }
                      }>{item.name}</NavLink>
                    ))}
                  </div>
                </div>
              </div>

              {/* Profile dropdown */}
              {isAuthenticated ? (
                <Menu as="div" className="ml-3 relative">
                  <div>
                    <Menu.Button className="flex text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white">
                      <span className="sr-only">Open user menu</span>
                      <UserCircleIcon className="h-10 w-10 rounded-full" />
                    </Menu.Button>
                  </div>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-gray-800 ring-1 ring-black ring-opacity-5 focus:outline-none">
                      <Menu.Item>
                        {({ active }) => (
                          <AuthenticationButton className={'text-gray-300 hover:bg-gray-700 hover:text-white block px-3 py-2 my-1 rounded-md text-base font-medium first-letter w-full text-left'} />
                        )}
                      </Menu.Item>
                    </Menu.Items>
                  </Transition>
                </Menu>
              ) : (
                <div className="ml-auto z-10">

                  <AuthenticationButton className={""} />
                </div>
              )
              }
            </div>
          </div>

          {/* Mobile menu */}
          <Disclosure.Panel className="sm:hidden">
            <div className="mx-3 px-2 py-5 bg-gray-800 rounded-md">
              {navigation.map((item) => (
                <NavLink onClick={() => close()} exact to={{
                  pathname: item.href,
                  state: { isNavOpen: open }
                }} key={item.name} className={isActive => {
                  return classNames(
                    isActive ? `bg-gray-900 text-white` : `text-gray-300 hover:bg-gray-700 hover:text-white`,
                    `block px-3 py-2 my-1 rounded-md text-base font-medium w-full first-letter
                    `
                  )
                }
                }>{item.name}</NavLink>
              ))}
            </div>
          </Disclosure.Panel>
        </>
      )
      }
    </Disclosure >
  )
}
