import { toast } from "react-toastify";

export const toastError = (friendlyError: string, rawError: any) => {
  toast.error(`There was an error updating the ${friendlyError} (${rawError}), please check your connectivity / report an issue!`, {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
}

export const toastInfo = (msg: string) => {
  toast.info(msg, {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
}
