import React, { Fragment } from "react";
import { Route, Switch } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";

import ProtectedRoute from './auth/ProtectedRoute';

import NavBar from './components/NavBar';
import Loading from "./components/Loading";

import LandingPage from "./routes/LandingPage";
import { sec } from "./app/security";
import { OverviewManager } from "./features/areas/OverviewManager";
import { HabitManager } from "./features/habit_manager/HabitManager";
import { Login } from "./components/Login";
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import TodoTodayManager from "./features/todo_today/TodoTodayManager";
import { interpolateColor } from "./utils/interpolateColor";
import { useGetUserQuery } from "./services/userApi";
import { ReactComponent as DiscordLogo } from './assets/discord-logo.svg';

const AppMain = ({
  isAuthenticated
}: {
  isAuthenticated: boolean;
}) => {

  const { data: userData, isLoading: isLoadingUserData, isError: isLoadingUserErrored } = useGetUserQuery()

  let LoggedinRoutes = () => (
    <Switch>
      <ProtectedRoute path="/" exact={true} component={OverviewManager} />
      <ProtectedRoute path="/settings" exact={true} component={HabitManager} />
      <ProtectedRoute path="/todo_today" exact={true} component={TodoTodayManager} />
    </Switch>
  )

  if (isLoadingUserData) {
    return <Loading />;
  }

  if (isLoadingUserErrored) {
    console.log("ERRORED")
    return (<div className="container mx-auto h-full">
      <NavBar isAuthenticated={isAuthenticated} backgroundColors={["", ""]} />
      <div className="px-3 sm:px-0 pt-20">
        {/* <LoggedinRoutes /> */}
      </div>
      <div>Sorry, something went wrong bootstrapping the app, we're looking into it!</div>;
    </div>)
  }

  if (!userData) {
    return <div>No user data</div>;
  }

  let actualUser = userData as any

  //Blue into grey
  let backgroundColor = interpolateColor([56, 189, 248], [165, 183, 189], actualUser.health / 100)

  let gradientColor = actualUser.health > 10 ?
    interpolateColor([56, 189, 248], [165, 183, 189], (actualUser.health - 10) / 100) : backgroundColor


  // linear-gradient(#7ed6df, #c7ecee)

  return (
    <React.Fragment>
      <div id="app" style={{ background: `linear-gradient(${backgroundColor}, ${gradientColor})` }} className={"h-auto min-h-full"}>
        <div className="container mx-auto h-full">
          <NavBar isAuthenticated={isAuthenticated} backgroundColors={[backgroundColor, gradientColor]} />
          <div className="px-3 sm:px-0 pt-20">
            <LoggedinRoutes />

          </div>

        </div>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      </div>
    </React.Fragment >
  )
}

const Footer = () => {

  const navigation = {


    main: [
      { name: 'Privacy', href: '#' },
    ],
    social: [
      {
        name: 'Discord',
        href: '#',
        icon: (props: any) => (
          <DiscordLogo {...props} />
        )
        //   <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
        //     <path
        //       fillRule="evenodd"
        //       d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z"
        //       clipRule="evenodd"
        //     />
        //   </svg>
        // ),
      },
    ],
  }

  return (
    <footer className="">
      <div className="max-w-7xl mx-auto py-12 px-4 overflow-hidden sm:px-6 lg:px-8">
        <nav className="-mx-5 -my-2 flex flex-wrap justify-center" aria-label="Footer">
          {navigation.main.map((item) => (
            <div key={item.name} className="px-5 py-2">
              <a href={item.href} className="text-base text-gray-500 hover:text-gray-900">
                {item.name}
              </a>
            </div>
          ))}
        </nav>
        <div className="mt-8 flex justify-center space-x-6">
          {navigation.social.map((item) => (
            <a key={item.name} href={item.href} className="text-gray-400 hover:text-gray-500">
              <span className="sr-only">{item.name}</span>
              <item.icon className="h-6 w-6" aria-hidden="true" />
            </a>
          ))}
        </div>
        {/* <p className="mt-8 text-center text-base text-gray-400">&copy; 2022 Blueprnt, Inc. All rights reserved.</p> */}
      </div>
    </footer>
  )
}

const App = () => {

  const { isLoading, isAuthenticated } = useAuth0();
  const { getAccessTokenSilently } = useAuth0();

  // set getAccessTokenSilently in global module to reuse it outside a React component
  sec.setAccessTokenSilently(getAccessTokenSilently);

  if (isLoading) {
    return <Loading />;
  }

  // Now we can render the app as normal

  {/* return (<div id="app" style={{ background: `linear-gradient(#7ed6df, #c7ecee)` }} className={"h-auto min-h-full"}> */ }

  if (!isAuthenticated) {
    return (
      <Fragment>
        <div id="app" className={""}>
          <div className="container mx-auto h-full">
            <NavBar isAuthenticated={isAuthenticated} backgroundColors={["#fff", "#fff"]} />
            <div className="px-3 sm:px-0 pt-20">
              <Switch>
                <React.Fragment>
                  <Route path="/" exact component={LandingPage} />
                  <Route path="/login" exact component={Login} />
                  <ProtectedRoute path="/settings" exact={true} component={HabitManager} />
                  <ProtectedRoute path="/todo_today" exact={true} component={TodoTodayManager} />
                </React.Fragment>
              </Switch>

            </div>
          </div>
        </div >
        <Footer />

      </Fragment >
    )
  }

  return (
    <React.Fragment>
      <Switch>
        {/* Only load login here, so we can immediately redirect to
        login and validate user once authenticated (this will create user if they don't exist) */}

        <Route path="/login" exact component={Login} />
      </Switch>

      <AppMain isAuthenticated={isAuthenticated} />
    </React.Fragment >
  )


};

export default React.memo(App);
