// src/components/logout-button.js

import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';

const LogoutButton = ({ className }: {
  className: string
}) => {
  const { logout } = useAuth0();
  return (
    <button
      className={className}
      onClick={() =>
        logout({
          returnTo: window.location.origin,
        })
      }
    >
      Logout
    </button>
  );
};


export default LogoutButton;