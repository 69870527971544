
import LoginButton from './LoginButton';
import LogoutButton from './LogoutButton';

import { useAuth0 } from '@auth0/auth0-react';
import Loading from './Loading';

const AuthenticationButton = ({
  className
}: {
  className: string
}) => {
  const { isAuthenticated, isLoading } = useAuth0();

  if (isLoading) {
    return <Loading />;
  }
  return isAuthenticated ? <LogoutButton className={className} /> : <LoginButton className={className} />;
};

export default AuthenticationButton;