import React from "react";
const loadingImg =
  "https://cdn.auth0.com/blog/auth0-react-sample/assets/loading.svg";

const Loading = () => (
  <div className="flex h-full justify-center items-center">
    <img className=" w-20 h-20" src={loadingImg} alt="Loading..." />
  </div>
);

export default Loading;

